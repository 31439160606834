@import 'include-media';

/* Material */

button.mat-button,
button.mat-flat-button,
button.mat-raised-button {
  line-height: var(--height-input);
  min-width: 100px;
  // font-size: 13px;
}

.mat-simple-snackbar-action button.mat-button {
  line-height: unset;
}

.mat-card {
  padding: 1.5rem;
  background-color: var(--color-background);

  &:not([class*='mat-elevation-z']) {
    box-shadow: var(--shadow);
  }

  @include media('<desktop') {
    padding: 0.5rem;
  }
}

.mat-menu-panel {
  box-shadow: var(--shadow-menu);
}

.mat-tooltip {
  font-size: 12px;
  background-color: var(--color-background-accent);
  padding: 8px 15px !important;
  font-weight: 600;
}

.mat-table {
  background: transparent;
  width: 100%;

  thead th {
    background-color: var(--color-background-2);
  }

  tbody td,
  thead th {
    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-bottom-width: 0;

    &:first-of-type {
      padding-left: 20px;
    }

    &:last-of-type {
      padding-right: 20px;
    }
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 44px;
  }

  tbody tr.mat-row {
    transition: background-color 150ms ease;

    &:hover {
      background-color: var(--color-background-2);
    }
  }

  .mat-header-cell {
    color: hsl(0, 0%, 40%);
  }
}

.mat-paginator {
  border-radius: 8px;
  background: var(--color-background-2);
}

mat-expansion-panel {
  background: transparent !important;

  &.no-styling {
    box-shadow: none !important;
    overflow: visible !important;

    &.mat-expanded .mat-expansion-panel-content:not(.ng-animating) {
      overflow: visible !important;
    }

    .mat-expansion-panel-content {
      overflow: hidden !important;
    }

    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
}

// Other components

.ng-select {
  .ng-select-container {
    height: var(--height-input) !important;
    min-height: var(--height-input) !important;
    font-size: 14px;
    font-family: var(--font-family);
    transition: border-color 150ms ease, border-radius 150ms ease, background-color 150ms ease;
    background-color: var(--color-background-2);
    color: var(--color-text);
    border-radius: var(--border-radius) !important;
    cursor: text;

    border: 1px solid transparent;
    // border: 1px solid hsla(0, 0%, 0%, 0.1);
    // border-top-color: transparent;
    // border-left-color: transparent;
    // border-right-color: transparent;

    .ng-value-container {
      padding-left: 1rem;

      .ng-input {
        padding-left: 1rem !important;
        padding-right: calc(40px + 1rem) !important;

        input {
          height: 28px;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container .ng-value-container .ng-input {
      padding-left: 10px !important;
      padding-right: 0 !important;
      padding-bottom: 6px !important;
    }

    .ng-select-container .ng-value-container .ng-placeholder {
      top: initial;
      padding-left: 10px;
    }
  }

  &.ng-select-opened,
  &.ng-select-focused {
    .ng-select-container {
      border: 1px solid var(--color-primary-focus) !important;
      box-shadow: none !important;
      background-color: var(--color-background-2);
    }
  }

  .ng-arrow-wrapper {
    padding-right: 0.8rem;
    width: 32px;
  }

  .ng-arrow {
    transition: var(--transition-slow);
    border-color: var(--color-icon-input) transparent transparent !important;
  }

  .ng-clear-wrapper {
    color: var(--color-icon-input);

    &:hover .ng-clear {
      color: var(--color-warn);
      transition: var(--transition);
    }
  }

  &.ng-select-opened > .ng-select-container .ng-arrow {
    border-width: 5px 5px 2.5px;
    transform: rotateX(180deg);
  }

  // Custom class for multiple styling
  .ng-select-multiple-label {
    display: flex;
    background-color: var(--color-background-accent);
    color: white;
    border-radius: 20px;
    margin-top: -4px;

    .number {
      padding: 6px 3px 6px 10px;
      font-weight: 500;
    }

    .clear {
      height: 24px;
      width: 24px;
      margin-top: 2px;
      margin-right: 2px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      transition: var(--transition);
      padding-bottom: 1px;

      &:hover {
        background-color: var(--color-background-accent-hover);
      }
    }
  }

  .image-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    app-avatar {
      margin-right: 0.5rem;
    }
  }
}

.ng-dropdown-panel {
  box-shadow: var(--shadow-menu) !important;
  // border-radius: 0 0 var(--border-radius) var(--border-radius) !important;
  border-radius: var(--border-radius) !important;
  border: none !important;
  margin-top: 4px !important;

  .ng-option {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex !important;
    align-items: center;
    min-height: var(--height-input);
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    transition: var(--transition);

    &:first-child {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    &.ng-option-marked {
      background-color: var(--color-background-2) !important;
    }

    &.ng-option-selected {
      background-color: var(--color-primary) !important;
      color: var(--color-primary-text) !important;
    }

    .image-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: -4px 0;

      app-avatar {
        margin-right: 0.5rem;
      }
    }
  }
}

// Emoji mart

.emoji-mart-search input {
  border: 1px solid transparent;
}

.emoji-mart-search-icon {
  top: 11px;
}

.emoji-mart-scroll {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #959595;
    border-radius: 10px;
  }
}
